<template>
    <v-container :fill-height="loading" class="pt-0">
        <v-container>
            <LoadingAnimation v-if="loading"/>
        </v-container>

        <v-card v-if="repeater.ID !== null">
            <v-toolbar :color="repeater.Status === 'Online' ? 'success' : 'error darken-1'" dark dense>
                <v-icon class="mr-2">mdi-radio-tower</v-icon>
                <span class="text-uppercase hidden-md-and-up">Repeater Details</span>
                <span class="text-uppercase hidden-sm-and-down">{{ repeater.Name ? repeater.Name : 'Untitled' }}</span>
                <span class="hidden-sm-and-down">: {{ $_.trim(repeater.Location) }}, {{ repeater.State }}</span>
                <v-spacer></v-spacer>
                <v-rating
                    v-model="repeater.Starred"
                    clearable
                    length="1"
                    color="yellow"
                    background-color="white"
                    title="Favorite Repeater"
                    :readonly="!authUser.authenticated"
                >
                </v-rating>
            </v-toolbar>

            <v-container class="px-0 pb-0">
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-subtitles-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater.Name ? repeater.Name : 'Untitled' }}</v-list-item-title>
                                <v-list-item-subtitle>Name</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line :to="'/map/' + repeater.ID">
                            <v-list-item-avatar>
                                <v-icon>mdi-map</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ $_.trim(repeater.Location) }}, {{ repeater.State }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Location</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon small>mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line :to="'/user/' + repeater.Owner.Username">
                            <UserAvatarList
                                :user="repeater.Owner"
                                small
                                v-if="repeater.Owner"
                            />

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater.Owner.Username }}</v-list-item-title>
                                <v-list-item-subtitle>Owner</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon small>mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item v-if="repeater.Owner.Requests">
                            <v-list-item-content>
                                <v-btn
                                    class="mx-auto"
                                    color="rfaccent2"
                                    dark
                                    block
                                    :disabled="!authUser.authenticated || dialogs.access.loading"
                                    :loading="dialogs.access.loading"
                                    @click="openAccessDialog"
                                >
                                    <v-icon class="mr-1">mdi-comment-question</v-icon>
                                    Request Access
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item two-line v-else>
                            <v-list-item-avatar>
                                <v-icon>mdi-comment-off</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>Disabled by Owner</v-list-item-title>
                                <v-list-item-subtitle>Access Requests</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-information-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater.Type }}</v-list-item-title>
                                <v-list-item-subtitle>Type</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-radio-fm</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                        repeater.Frequency ? repeater.Frequency.replace('46X', '462') + ' MHz'
                                            : ''
                                    }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Frequency</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-login-variant</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater['PL In'] ? repeater['PL In'] : 'SIGN IN TO VIEW' }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Input Tone</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-logout-variant mdi-rotate-180</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater['PL Out'] ? repeater['PL Out'] : 'SIGN IN TO VIEW' }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Output Tone</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon color="success" v-if="repeater.Status === 'Online'">mdi-power</v-icon>
                                <v-icon color="error" v-else>mdi-power</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater.Status }}</v-list-item-title>
                                <v-list-item-subtitle>Status</v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-ruler</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ range }} Miles</v-list-item-title>
                                <v-list-item-subtitle>Range (Estimate)</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">
                        <v-list-item two-line>
                            <v-list-item-avatar>
                                <v-icon>mdi-clock-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater.Modified | fromNow }}</v-list-item-title>
                                <v-list-item-subtitle>Last Updated</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0" v-if="config.NETWORK_ENABLED && repeater.Node">
                        <v-list-item two-line :href="'https://network.mygmrs.com/map/' + repeater.Node">
                            <v-list-item-avatar>
                                <v-icon>mdi-router-wireless-settings</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ repeater.Node }}</v-list-item-title>
                                <v-list-item-subtitle>myGMRS Network Node</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-icon small>mdi-open-in-new</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-col>
                    <!--                <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0">-->
                    <!--                    <v-list-item>-->
                    <!--                        <v-list-item-content>-->
                    <!--                            <v-list-item-title>-->
                    <!--                                <v-rating color="yellow" background-color="grey"></v-rating>-->
                    <!--                            </v-list-item-title>-->
                    <!--                        </v-list-item-content>-->
                    <!--                    </v-list-item>-->
                    <!--                </v-col>-->
                </v-row>

                <v-row>
                    <v-col cols="12" id="map-container">
                        <RepeaterMap
                            :repeater="repeater"
                            height="400"
                            lazy
                        />
                    </v-col>
                </v-row>
            </v-container>

            <v-card-text class="mt-10" v-html="normalizeStyles(repeater.Rules)" v-if="repeater.Rules"></v-card-text>
            <v-card-text v-else-if="!authUser.authenticated" class="mt-5 pb-1">
                <v-alert type="warning">
                    You must be logged in to view detailed repeater information and comments.
                </v-alert>
            </v-card-text>
        </v-card>

        <!--                                <h2 class="title font-weight-light">Repeater Rating</h2>-->
        <!--                                <v-rating color="yellow" background-color="grey"></v-rating>-->

        <v-expansion-panels
            v-model="panelComments"
            class="mt-5"
            flat
            v-if="authUser.authenticated && repeater.ID !== null && $_.isArray(comments)">
            <v-expansion-panel>
                <v-expansion-panel-header color="rfaccent2" class="white--text title font-weight-light">
                    <div>
                        <v-icon dark class="mr-2">mdi-comment-text-multiple</v-icon>
                        <span>Comments</span>
                    </div>
                    <template v-slot:actions>
                        <v-icon color="white">
                            mdi-chevron-down
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-2 px-0">
                    <v-card class="mb-2 elevation-0">
                        <div v-if="comments.length > 0">
                            <v-data-iterator
                                :items="comments"
                                item-key="id"
                                :items-per-page="5"
                                sort-by="id"
                                sort-desc
                                :footer-props="{'items-per-page-text': 'Comments per page'}"
                            >
                                <template v-slot:default="{ items, isExpanded, expand }">
                                    <v-card
                                        class="mb-2"
                                        :color="$vuetify.theme.dark ? 'almostblack' : 'white'"
                                        v-for="(comment, index) in items"
                                        :key="index"
                                        tile
                                    >
                                        <v-card-subtitle class="font-weight-medium pt-0 pb-0">
                                            <v-container class="px-0">
                                                <v-row>
                                                    <v-col class="flex-grow-0 flex-shrink-1">
                                                        <router-link :to="'/user/' + comment.username"
                                                                     class="no-underline">
                                                            <UserAvatar
                                                                :user="comment"
                                                                xsmall
                                                            />
                                                        </router-link>
                                                    </v-col>
                                                    <v-col class="flex-grow-1 flex-shrink-0 pl-0">
                                                        <div class="caption text-uppercase mt-2">
                                                            Posted {{ moment(comment.date).fromNow() }} by
                                                            <router-link :to="'/user/' + comment.username">{{ comment.username }}</router-link>
                                                            <span class="hidden-sm-and-down ml-1">(<router-link
                                                                :to="'/license/' + comment.callsign">{{
                                                                    comment.callsign
                                                                }}</router-link>)</span>
                                                        </div>
                                                    </v-col>
                                                    <v-col class="flex-grow-0 flex-shrink-1">
                                                        <div class="d-flex">
                                                            <v-btn
                                                                icon
                                                                title="Report Comment"
                                                                @click="confirmReportComment(comment.id)"
                                                            >
                                                                <v-icon color="error">mdi-flag</v-icon>
                                                            </v-btn>
                                                            <v-btn
                                                                icon
                                                                title="Delete Comment"
                                                                @click="confirmDeleteComment(comment.id)"
                                                                v-if="isRepeaterOwner || (comment.username === authUser.username)"
                                                            >
                                                                <v-icon>mdi-close</v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-subtitle>
                                        <v-card-text class="body">
                                            {{ comment.comment }}
                                        </v-card-text>
                                    </v-card>
                                </template>

                            </v-data-iterator>
                        </div>

                        <v-card v-else flat>
                            <v-card-text>
                                <v-icon small class="mr-1">mdi-alert</v-icon>
                                No comments have been posted yet!
                            </v-card-text>
                        </v-card>
                    </v-card>

                    <h3 class="font-weight-light mb-2">Post a Comment</h3>

                    <div class="text-right">
                        <form accept-charset="UTF-8">
                            <v-textarea
                                v-model="comment.comment"
                                label="Comment"
                                outlined
                                counter
                                maxlength="300"
                                rows="3"
                                hint="Remember, comments are subject to our Terms and Conditions and must not discuss tones or other private details"
                            ></v-textarea>

                            <v-btn
                                color="rfaccent2"
                                @click="postComment"
                                :loadibg="commentLoading"
                                dark
                            >
                                Post Comment
                            </v-btn>
                        </form>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            bottom
        >
            <v-alert :type="snackbar.type" class="mb-0" dense>
                {{ snackbar.text }}
            </v-alert>
        </v-snackbar>

        <v-dialog
            v-model="dialogs.access.state"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            content-class="repeater-dialog"
            xattach=""
            width="600"
        >
            <v-card>
                <v-toolbar color="rfaccent2" dark dense>
                    <v-toolbar-title>
                        <v-icon>mdi-comment-question</v-icon>
                        Request Repeater Access
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="dialogs.access.state = false">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="requestAccess" v-model="dialogs.access.formValid">
                        <p class="mt-2">
                            The repeater owner may contact you to decide whether to approve your request and keep your
                            contact information on file as part of their FCC station records.
                        </p>
                        <v-text-field
                            v-model="authUser.callsign"
                            label="Your GMRS Callsign"
                            outlined
                            disabled
                            readonly
                            hide-details
                            class="mt-5"
                        ></v-text-field>

                        <p class="ml-2 caption">Need to
                            <router-link to="/profile/edit">update your callsign</router-link>
                            ?
                        </p>

                        <v-text-field
                            v-model="authUser.email"
                            label="Your Email"
                            outlined
                            disabled
                            readonly
                            hide-details
                            class="mt-5"
                        ></v-text-field>

                        <p class="ml-2 caption">Need to
                            <router-link to="/profile/edit">update your email address</router-link>
                            ?
                        </p>

                        <vue-phone-number-input
                            v-model="dialogs.access.phone"
                            class="mt-5"
                            :dark="$vuetify.theme.dark"
                            :valid-color="$vuetify.theme.themes.dark.success"
                            :error-color="$vuetify.theme.themes.dark.error"
                            :dark-color="$vuetify.theme.themes.dark.almostblack"
                            :only-countries="['US', 'PR', 'VI', 'GU', 'AS', 'MP', 'CA', 'MX']"
                            default-country-code="US"
                            required
                            hint="The repeater owner may contact you to approve your request and keep it as part of their FCC station records."
                            persistent-hint
                            @update="dialogs.access.phoneResult = $event"
                        />

                        <v-slider
                            v-model="dialogs.access.members"
                            class="mt-10"
                            label="Users"
                            thumb-label="always"
                            min="1"
                            max="10"
                            :rules="[dialogs.access.rules.required]"
                            hint="How many users, including yourself, will be using this repeater?"
                            persistent-hint
                        ></v-slider>

                        <v-autocomplete
                            v-model="dialogs.access.uses"
                            class="mt-10"
                            :items="dialogs.access.usesItems"
                            label="Requested Usage"
                            chips
                            deletable-chips
                            multiple
                            outlined
                            :rules="[dialogs.access.rules.required]"
                            hint="What purposes do you plan to use this repeater for?"
                            persistent-hint
                        ></v-autocomplete>

                        <v-textarea
                            v-model="dialogs.access.notes"
                            class="mt-5"
                            label="Notes"
                            outlined
                            :rules="[dialogs.access.rules.required]"
                            hint="Tell the owner what you will use this repeater for and any other information they may use to decide whether to approve your request."
                            persistent-hint
                        ></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pb-15">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mt-5"
                        color="rfaccent2"
                        dark
                        :disabled="dialogs.access.loading"
                        :loading="dialogs.access.loading"
                        @click="requestAccess"
                    >
                        Submit Request
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogs.report.state"
            max-width="700"
        >
            <v-card>
                <v-toolbar color="rfaccent2" dark dense>
                    <v-toolbar-title class="font-weight-light">
                        <v-icon>mdi-comment-alert</v-icon>
                        Report Comment
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="closeReportDialog">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <!--                    <v-form ref="reportComment" v-model="dialogs.report.formValid">-->
                    <v-select
                        v-model="dialogs.report.reason"
                        label="Reason for Report"
                        :items="dialogs.report.reasons"
                        outlined
                        hide-details
                        class="mt-5"
                    >
                    </v-select>

                    <v-textarea
                        v-model="dialogs.report.details"
                        label="Report Details"
                        hint="Explain why this comment may be against our Terms and Conditions"
                        persistent-hint
                        outlined
                        counter
                        :rules="dialogs.report.rulesDetails"
                        class="mt-5"
                    >
                    </v-textarea>
                    <!--                    </v-form>-->
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="rfaccent2" dark @click="reportComment">Send Report</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogs.delete.state"
            max-width="500"
        >
            <v-card>
                <v-toolbar color="rfaccent2" dark dense>
                    <v-toolbar-title class="font-weight-light">
                        <v-icon>mdi-comment-remove</v-icon>
                        Delete Comment
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="closeDeleteDialog">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <p class="mt-5">
                        Are you sure you want to delete this comment? This cannot be undone.
                    </p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="rfaccent2" dark @click="closeDeleteDialog">Cancel</v-btn>
                    <v-btn color="error" dark @click="deleteComment">Delete Comment</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
    img {
        max-width: 100%;
        height: auto;
    }

    #map-container {
        height: 400px;
    }
</style>

<script>
    import UserAvatar from '@/components/UserAvatar.vue';
    import UserAvatarList from '@/components/UserAvatarList.vue';
    import RepeaterMap from '@/components/RepeaterMap.vue';
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import moment from 'moment';
    import axios from 'axios';
    import config from '../config';

    export default {
        name: 'Repeater',

        components: {
            UserAvatar,
            UserAvatarList,
            RepeaterMap,
            LoadingAnimation
        },

        props: ['repeater', 'comments', 'authUser', 'loading'],

        data: () => ({
            config: config,
            panelComments: 0,
            commentLoading: false,
            comment: {
                user: null,
                date: null,
                repeater: null,
                comment: null
            },
            snackbar: {
                state: false,
                color: 'info',
                type: 'info',
                text: ''
            },
            dialogs: {
                access: {
                    state: false,
                    loading: false,
                    formValid: false,
                    members: 1,
                    notes: null,
                    phone: null,
                    phoneResult: null,
                    uses: null,
                    usesItems: ['Family Communications', 'Emergency Communications', 'When Travelling', 'Personal/Hobby Communications', 'Business Use (License Required for Each User)'],
                    rules: {
                        required: v => !!v || 'Required',
                    }
                },
                report: {
                    state: false,
                    id: null,
                    details: null,
                    rulesDetails: [v => v.length <= 200 || 'Max 200 characters'],
                    reasons: [
                        'Inappropriate Content',
                        'Spam',
                        'Other (Please Specify Below)'
                    ]
                },
                delete: {
                    state: false,
                    id: null
                }
            }
        }),

        watch: {
            "repeater.Starred"() {
                this.setFavorite();
            }
        },

        computed: {
            isRepeaterOwner() {
                return this.repeater?.Owner?.ID === this.authUser?.id;
            },

            range() {
                if (!this.repeater.Radius) {
                    const distRepeater = ((4.12 * Math.sqrt(this.repeater.HAAT / 3.281)) / 1.609);
                    const distRadio = ((4.12 * Math.sqrt(5 / 3.281)) / 1.609);
                    return Math.round((distRadio + distRepeater) * 10) / 10;
                } else {
                    return this.repeater.Radius;
                }
            },

            requestAccessValid() {
                return this.dialogs.access.formValid &&
                    (this.dialogs.access.phoneResult && this.dialogs.access.phoneResult.isValid);
            }
        },

        filters: {
            fromNow(date) {
                return moment(date).fromNow();
            }
        },

        methods: {
            normalizeStyles(str) {
                return this.$sanitize(str);
            },

            moment(date) {
                return moment(date);
            },

            setFavorite() {
                let axiosHeaders = {};
                if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                axios.request({
                    url: config.API_LOCATION + '/favorite/repeater/' + this.repeater.ID,
                    headers: axiosHeaders,
                    method: this.repeater.Starred ? 'post' : 'delete'
                })
                    // .then(response => {
                    //     console.log(response.data);
                    // })
                    .catch(err => {
                        console.error(err);
                    });
            },

            postComment() {
                //@todo form validation, and this ideally should be on the parent page

                this.comment.user = this.authUser.id;
                this.comment.repeater = this.repeater.ID;
                this.comment.date = moment.utc().format();

                this.commentLoading = true;

                let axiosHeaders = {};
                if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                axios
                    .post(config.API_LOCATION + '/comment', this.comment, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.comment.comment = null;

                            //notify parent to poll for comments again
                            this.$emit('newcomment');

                            this.snackbar.color = 'success';
                            this.snackbar.type = 'success';
                            this.snackbar.text = `Your comment has been posted successfully.`;
                            this.snackbar.state = true;
                        } else {
                            this.snackbar.color = 'error';
                            this.snackbar.type = 'error';
                            this.snackbar.text = `An error occurred: ${response.data.message}`;
                            this.snackbar.state = true;
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            this.snackbar.color = 'error';
                            this.snackbar.type = 'error';
                            this.snackbar.text = `An error occurred: ${err.message}`;
                            this.snackbar.state = true;
                        }
                    })
                    .then(() => {
                        this.commentLoading = false;
                    });
            },

            deleteComment() {
                let axiosHeaders = {};

                if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                axios({
                    method: 'delete',
                    url: config.API_LOCATION + '/comment/' + this.dialogs.delete.id,
                    headers: axiosHeaders
                })
                    .then(response => {
                        this.closeDeleteDialog();

                        console.log(response.data);

                        //notify parent to poll for comments again
                        this.$emit('newcomment');

                        this.snackbar.color = 'success';
                        this.snackbar.type = 'success';
                        this.snackbar.text = `The comment has been deleted successfully.`;
                        this.snackbar.state = true;
                    })
                    .catch(err => {
                        console.error(err);

                        this.snackbar.color = 'error';
                        this.snackbar.text = 'An error occurred while deleting the comment. ' + err.response?.data?.message
                        this.snackbar.type = 'error';
                        this.snackbar.state = true;
                    });
            },

            confirmDeleteComment(id) {
                this.dialogs.delete.state = true;
                this.dialogs.delete.id = id;
            },

            closeDeleteDialog() {
                this.dialogs.delete.state = false;
                this.dialogs.delete.id = null;
            },

            confirmReportComment(id) {
                this.dialogs.report.state = true;
                this.dialogs.report.id = id;
            },

            closeReportDialog() {
                this.dialogs.report.state = false;
                this.dialogs.report.id = null;
                this.dialogs.report.reason = null;
                this.dialogs.report.details = null;
            },

            reportComment() {
                let axiosHeaders = {};
                if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                axios
                    .post(config.API_LOCATION + '/comment/report/' + this.dialogs.report.id, {
                        id: this.dialogs.report.id,
                        details: this.dialogs.report.details,
                        reason: this.dialogs.report.reason
                    }, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.closeReportDialog();

                        console.log(response.data);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            },

            requestAccess() {
                if (this.requestAccessValid) {
                    this.dialogs.access.loading = true;

                    let axiosHeaders = {};
                    if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                    const requestObj = {
                        // callsign: this.authUser.callsign,
                        // email: this.authUser.email,
                        from: this.authUser.id,
                        to: this.repeater.Owner.ID,
                        repeater: this.repeater.ID,
                        phone: this.dialogs.access.phoneResult.formattedNumber,
                        members: this.dialogs.access.members,
                        uses: this.dialogs.access.uses,
                        notes: this.dialogs.access.notes
                    };

                    axios
                        .post(config.API_LOCATION + '/request/repeater', requestObj, {
                            headers: axiosHeaders
                        })
                        .then(response => {
                            this.dialogs.access.state = false;

                            this.dialogs.access.members = 1;
                            this.dialogs.access.phone = null;
                            this.dialogs.access.uses = null;
                            this.dialogs.access.notes = null;

                            if (response.data.success) {
                                this.snackbar.color = 'success';
                                this.snackbar.type = 'success';
                            } else {
                                this.snackbar.color = 'error';
                                this.snackbar.type = 'error';
                            }

                            this.snackbar.text = `${response.data.message}`;
                            this.snackbar.state = true;
                        })
                        .catch(err => {
                            this.snackbar.color = 'error';
                            this.snackbar.type = 'error';
                            this.snackbar.text = `An error occurred when submitting your request: ${err}`;
                            this.snackbar.state = true;
                        })
                        .then(() => {
                            this.dialogs.access.loading = false;
                        });
                } else {
                    this.snackbar.color = 'error';
                    this.snackbar.type = 'error';
                    this.snackbar.text = 'Please fill out all required fields.';
                    this.snackbar.state = true;
                }
            },

            openAccessDialog() {
                //check if we have a pending request for this repeater already, so we won't spam the owner with multiple requests
                this.dialogs.access.loading = true;

                let axiosHeaders = {};
                if (this.authUser.authToken) axiosHeaders.Authorization = `Bearer ${this.authUser.authToken}`;

                axios
                    .get(config.API_LOCATION + '/request/repeater', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        console.log(response.data);

                        //@todo check if there was a pending request and block the dialog opening if so

                        this.dialogs.access.state = true;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.dialogs.access.loading = false;
                    });
            }
        }
    };
</script>
